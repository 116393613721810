import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import i18n from "../../../i18n";
import { Helmet } from 'react-helmet';
import { useCart } from 'react-use-cart';
import Layout from '../../components/Layout';
import NavBarMenu from '../../components/NavBarMenu';
import ProductQuantityCard from '../../components/ProductQuantityCard/index';
import TotalPricingBloc from '../../components/TotalPricingBloc/index';
import { ChevronLeftIcon } from "@heroicons/react/solid";
import generateMeta from '../../utils/meta';
import { useSelector, useDispatch } from 'react-redux';
import { checkOfferLaunch } from './reducer';
import Loader from '../../components/Loader';

function generateTotalCategoryHierarchy(node) {
  if (!node) return [];
  let prefix = [];
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalCategoryHierarchy(node.parentCategory);
  }
  prefix.push(node);
  return prefix;
}

function generateTotalSlug(node) {
  if (!node) return "";
  let prefix = "";
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalSlug(node.parentCategory);
  }
  return prefix + "/" + node.slug;
}

const today = new Date();

const ShoppingCartPage = ({ data, pageContext }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  // i18n used for translation
  i18n(pageContext.locale);

  const {
    totalItems,
    items,
    isEmpty,
    updateItemQuantity,
    removeItem,
    updateItem,
    getItem,
  } = useCart();

  const dispatch = useDispatch();

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  const { isLoading, offerChecked, errors } = useSelector((state) => ({
    isLoading: state.getIn(["shoppingCartPage", "isLoading"]),
    offerChecked: state.getIn(["shoppingCartPage", "offerChecked"]),
    errors: state.getIn(["shoppingCartPage", "errors"]),
  }))

  // Forging an array of the IDs of the cart product
  let cartItems = []
  items.map((item) => {
    return (
      cartItems.push({ offerId: item.offerId, productId: item.id }))
  })

  // useEffect to request the API
  useEffect(() => {
    if (totalItems !== 0) { dispatch(checkOfferLaunch({ cartItems })) }
  }, []);

  // useEffect to update the cart
  useEffect(() => {
    if (offerChecked !== null && !isEmpty) {
      offerChecked.map((offer) => {
        let cartItem = getItem(offer.productId);
        if (cartItem && offer.isAvailable) {
          const startDate = offer.crossedPriceStartDate !== null && offer.crossedPriceStartDate !== "null" ? new Date(offer.crossedPriceStartDate) : null;
          const endDate = offer.crossedPriceEndDate !== null && offer.crossedPriceEndDate !== "null" ? new Date(offer.crossedPriceEndDate) : null;
          let price = Number(offer.priceWithVAT);
          let crossedPrice = offer.crossedPriceWithVAT;
          if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
            let cartUpdatedItem = {
              price: offer.crossedPriceWithVAT,
              crossedPrice: null,
            }
            updateItem(cartItem.id, cartUpdatedItem);
          }
          else {
            let cartUpdatedItem = {
              price: offer.priceWithVAT,
              crossedPrice: offer.crossedPriceWithVAT,
            }
            updateItem(cartItem.id, cartUpdatedItem);
          }
        }
        else if (cartItem && !offer.isAvailable) { removeItem(cartItem.id) }
      })
    }
  }, [offerChecked]);


  let allSeller = [];
  let treatedSellerId = []; // Permits to manage duplicate Seller
  items.map((item, key) => {
    if (!(treatedSellerId.includes(item.sellerId))) {
      allSeller.push({
        corporateName: item.corporateName,
        sellerId: item.sellerId
      })
    }
    treatedSellerId.push(item.sellerId);
    return null;
  })

  return (
    <Layout
      currentPath={pageContext.currentPath}
      lang={pageContext.locale}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>Mon panier</title>
        {/* Seo and OpenGraph */}
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <div className="max-w-screen-2xl mx-auto">
        <Link to="/" className="pl-6 flex text-grey items-center underline">
          <ChevronLeftIcon
            className="flex-shrink-0 h-4 w-4 text-grey"
            aria-hidden="true"
          />
          Continuer mes achats
        </Link>
        {isLoading &&
          <Loader />
        }
        {errors &&
          <div>Une erreur s'est produite. Merci de réactualiser la page</div>}
        {
          !isEmpty && !isLoading && !errors &&
          <div className="py-4 flex flex-col lg:flex-row">
            {/* Cart Section */}
            <div className="px-2 w-full lg:w-3/5 xl:w-3/4">
              <div class="w-full px-2 sm:px-6 pt-4">
                <div class="flex justify-start item-start flex-col pt-4">
                  <h3 class="text-sm md:text-semi font-medium">Mon panier</h3>
                </div>
                <div class="w-full space-y-4 md:space-y-6 xl:space-y-8">
                  <div class="flex flex-col justify-start items-start w-full divide-y divide-grey-light">
                    {/* map here */}
                    {
                      allSeller.map((seller, sellerKey) => {
                        return (
                          <div class="py-6 w-full">
                            <h3 class="pl-2 text-s text-grey-darkest">Vendu et expédié par <span className="text-orange-dark font-medium">{seller.corporateName}</span></h3>
                            <div class="pl-2 md:pl-4 w-full flex flex-col">
                              {/* Product Listing by Seller */}
                              <div class="w-full p-3 space-y-4">
                                {
                                  items.map((product, key) => {
                                    // display product by seller without delivery simulation information
                                    if (product.sellerId === seller.sellerId) {
                                      return (
                                        <ProductQuantityCard
                                          key={key}
                                          product={product}
                                          removeAction={() => removeItem(product.id)}
                                          PlusQuantityAction={() => updateItemQuantity(product.id, product.quantity + 1)}
                                          MinusQuantityAction={() => updateItemQuantity(product.id, product.quantity - 1)}
                                          Quantity={product.quantity}
                                        />
                                      )
                                    } return null;
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* Summary block */}
            <div className="px-2 w-full lg:w-2/5 xl:w-1/4">
              <TotalPricingBloc
                lang={pageContext.locale}
                totalItems={totalItems}
                items={items}
              />
            </div>
          </div>
        }
        {
          isEmpty && !isLoading && !errors &&
          <div className="flex justify-center py-40 md:py-60">
            <p>Votre panier est vide</p>
          </div>
        }
      </div>
    </Layout>
  );
};

export default ShoppingCartPage;

export const query = graphql`
            query( $locale: String! ) {
              menuFirstLevel: allContentfulCategorySve(filter: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
              edges {
              node {
              slug
          categoryName
        }
      }
    }
            menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
            sort : {fields: [slug], order: ASC}) {
              edges {
              node {
              slug
          categoryName
          categoryEncartTitle
            parentCategory {
              slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
            menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
            sort : {fields: [slug], order: ASC}) {
              edges {
              node {
              slug
          categoryName
          categoryEncartTitle
            parentCategory {
              slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
            allContentfulCategorySve(filter: {node_locale: {eq: $locale } }) {
              edges {
              node {
              categoryName
              categoryEncartTitle
          slug
            parentCategory {
              categoryName
              categoryEncartTitle
            }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/cart.md/"}) {
        html
        frontmatter {
            title
            description
            ogtitle
            ogdescription
        }
      }
  }
            `;
