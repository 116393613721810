import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

export const ShoppingCartModale = ({ showModale, hideModale, removeAction }) => {
  const classes = useStyles(getStyles());

  const [validate, setValidate] = useState(false);
  // auto close modal after 3s ONLY if order is refused or canceled
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => hideModale(), 3000);
    return () => { clearTimeout(timer); };
  }, [validate]);

  return (
    showModale
    && (
      <button
        type="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModale}
      >
        <div
          aria-hidden="true"
          className="shadow-2xl shadow-grey-darkest rounded relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2  flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          onClick={(e) => {
            // don't close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <div className="w-3/4 md:w-full flex flex-col items-center justify-center my-16 h-36 min-h-full">
            <>
              <span className="text-s md:text-xss text-center my-6">
                Voulez-vous vraiment supprimer ce produit ?
              </span>
              <form className="w-full flex flex-col items-center">
                <button
                  type="button"
                  className="text-xs text-white bg-orange-chocolate rounded-md px-6 py-2 hover:font-semibold focus:outline-none"
                  onClick={() => { removeAction(); hideModale() }}
                >
                  Valider la suppression
                </button>
              </form>
              <button className="text-xs px-4 py-2 capitalize focus:outline-none" type="button" onClick={hideModale}>retour</button>
            </>
          </div>
        </div>
      </button>
    )
  );
};

export default ShoppingCartModale;

ShoppingCartModale.propTypes = {
  showModale: PropTypes.bool.isRequired,
  hideModale: PropTypes.func.isRequired,
};
