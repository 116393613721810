import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlaceHolder from "../../../assets/placeholder.jpg";
import { Link } from 'gatsby';
import { ShoppingCartModale } from '../ShoppingCartModale/index';

const ProductQuantityCard = ({ product, removeAction, PlusQuantityAction, MinusQuantityAction, Quantity }) => {

  const [openModale, setOpenModale] = useState(false);
  const showModale = () => {
    setOpenModale(true);
  };
  const hideModale = () => {
    setOpenModale(false);
  };

  const minusOrRemove = () => {
    if (product.quantity === 1) {
      showModale();
    } else MinusQuantityAction();
  }
  return (
    <div class="flex justify-start flex-row items-center space-x-4">
      {/* Picture section */}
      <div className="w-1/6 pl-2 lg:pl-0 flex justify-center">
        <Link
          to={product.urlProductPage}
          className="flex lg:flex-col justify-end lg:items-center"
        >
          {product.image ?
            <img
              className="object-scale-down h-20 lg:h-28 w-full md:py-4"
              alt={product.image.description}
              src={product.image.file.url}
            />
            :
            <img
              className="object-scale-down h-20 lg:h-28 w-full md:py-4"
              src={PlaceHolder}
              alt="product"
            />
          }
        </Link>
      </div>
      {/* Information section */}
      <div class="w-5/6 flex justify-between items-start flex-row">
        <div className="flex w-full items-center">
          <div class="w-11/12 flex flex-col justify-start items-start space-y-0.5 md:space-y-1">
            <Link to={product.urlProductPage} >
              <h3 class="text-xs md:text-s font-medium">{product.title}</h3>
            </Link>
            <div class="flex justify-start items-start flex-col md:space-y-1.5">
              <div className="flex flex-col items-start md:items-center md:flex-row space-x-0 space-y-1 md:space-x-2 md:space-y-0">
                <p class="text-xs"><span>Prix unit. TTC :  </span> {((product.price * 100) / 100).toFixed(2)}€</p>
                <p class="text-s md:text-sm text-orange-darkest font-medium"><span>Total :  </span> {(product.price * product.quantity).toFixed(2)} €</p>
              </div>
              <div className="flex flex-row pt-1 space-x-2">
                <button
                  type="button"
                  className="focus:outline-none"
                  aria-label="retirer un article"
                  onClick={() => minusOrRemove()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-grey-light" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                  </svg>
                </button>

                <span className="px-4 font-bold text-orange-chocolate border border-grey-light">{product.quantity}</span>

                <button
                  type="button"
                  className="focus:outline-none"
                  aria-label="ajouter un article"
                  onClick={PlusQuantityAction}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-grey-light" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="w-1/12">
            <button
              type="button"
              className="focus:outline-none"
              aria-label="supprimer cet article du panier"
              onClick={showModale}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-grey-light" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <ShoppingCartModale
        removeAction={removeAction}
        showModale={openModale}
        hideModale={hideModale}
      />
    </div>
  );
}

export default ProductQuantityCard;

ProductQuantityCard.propTypes = {
  product: PropTypes.object.isRequired,
  removeAction: PropTypes.func.isRequired,
  PlusQuantityAction: PropTypes.func.isRequired,
  MinusQuantityAction: PropTypes.func.isRequired,
  Quantity: PropTypes.number.isRequired
};