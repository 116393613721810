import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const TotalPricingBloc = ({ lang, totalItems, items }) => {
  let totalPrice = 0;
  let uniqueItemPrice = 0;
  items.forEach((item) => {
    uniqueItemPrice = item.price * item.quantity;
    totalPrice += uniqueItemPrice;
  })

  return (
    <div className="flex flex-col justify-around rounded bg-orange w-full p-4 space-y-1">
      <span className="font-semibold text-sm">Récapitulatif</span>
      <span>
        Nombre d'articles : <span>{totalItems}</span>
      </span>
      <span>
        Frais de livraison : étape suivante
      </span>
      <span className="font-semibold border-1 border-t-grey">
        Total (TVA incluse) : <span>{totalPrice.toFixed(2)} €</span>
      </span>
      <Link to={`/${lang}/checkout/`} className="self-center lg:self-start text-s px-2 py-0.5 lg:py-2 font-bold w-full sm:w-1/2 lg:w-full">
        <button type="button" className="outline-none bg-orange-chocolate w-full rounded flex text-white py-4 px-2 justify-around hover:font-semibold">
          <span>Choix du mode de livraison</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </Link>
    </div>
  )
};

export default TotalPricingBloc;

TotalPricingBloc.propTypes = {
  totalItems: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired
};